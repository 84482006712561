import { notification } from 'antd'
import axios from 'axios'
import router from 'next/router'
export const BASE_URL = process.env.NEXT_PUBLIC_API_URL

// export const BASE_URL = 'http://192.168.1.52:8000/api/v1'
//export const BASE_URL = 'http://56c2-2a02-a210-2ac1-7000-71c7-9b71-fff1-2e65.ngrok.io/api/v1'
export function postActionTokenless(url, data) {
  return axios.post(`${BASE_URL}${url}`, data)
}

export function postAction(url, data, file = false) {
  let token = localStorage.getItem('authToken')
  let headers = {}
  headers.Authorization = 'Bearer ' + token
  if (file) {
    headers['Content-Type'] = 'multipart/form-data'
  }
  let config = { headers }
  if (token) {
    return axios.post(`${BASE_URL}${url}`, data, config)
  } else {
    notification.open({
      message: 'Devam Edebilmek İçin Lütfen Giriş Yapınız',
    })
    setTimeout(() => {
      router.push('/login')
    }, 2000)
  }
}

export function getActionTokenless(url) {
  return axios.get(`${BASE_URL}${url}`)
}

export function getAction(url) {
  let token = localStorage.getItem('authToken')
  let config = { headers: { Authorization: 'Bearer ' + token } }

  if (token) {
    return axios.get(`${BASE_URL}${url}`, config)
  } else {
    notification.open({
      message: 'Devam Edebilmek İçin Lütfen Giriş Yapınız',
    })
    setTimeout(() => {
      router.push('/login')
    }, 2000)
  }
}

export function getActionTokenParameters(url, token) {
  let config = { headers: { Authorization: 'Bearer ' + token } }
  return axios.get(`${BASE_URL}${url}`, config)
}

export function deleteAction(url) {
  let token = localStorage.getItem('authToken')
  let config = { headers: { Authorization: 'Bearer ' + token } }
  if (token) {
    return axios.delete(`${BASE_URL}${url}`, config)
  } else {
    notification.open({
      message: 'Devam Edebilmek İçin Lütfen Giriş Yapınız',
    })
    setTimeout(() => {
      router.push('/login')
    }, 2000)
  }
}
